import request from '@/utils/request'
export const funclist = (params) => { //获取首页工具
  return request({
    url: '/system/func/list',
    method: 'get',
    params
  })
}
export const funcclick = (data) => { //更改工具点击次数
  return request({
    url: '/system/func/click',
    method: 'post',
    data
  })
}
export const funcadd = (data) => { //添加工具
  return request({
    url: '/system/func/add',
    method: 'post',
    data
  })
}
export const funcremove = (data) => { //删除工具
  return request({
    url: '/system/func/remove',
    method: 'post',
    data
  })
}
export const getNewToken = (data) => { //获取新的token
  return request({
    url: '/system/ysys/token/get',
    method: 'post',
    data
  })
}
export const xiaolinguser = (data) => { //小灵公众号信息表单接口
  return request({
    url: '/system/xiaoling/add/user',
    method: 'post',
    data
  })
}
export const xiaolinggetuser = (params) => { //小灵判断用户信息是否存在
  return request({
    url: '/system/xiaoling/get/user',
    method: 'get',
    params
  })
}
// 访问计数接口
export const markput = (params) => {
  return request({
    url: '/system/user/mark/put',
    method: 'get',
    params
  })
}
