import {
    createApp
} from 'vue'
import 'vant/lib/index.css';
import vant from 'vant';
import App from './App.vue'
import router from './router'
import store from './store'
import {
    draggable
} from './directives'
import {
    getUserInfo
} from '@/utils/my'
import {
    showConfirmDialog
} from 'vant';
// import 'lib-flexible'
import '@/components/components/js/flexible'
import SvgIcon from '@/components/components/svg/svg'
import rowDisplay from '@/components/components/rowDisplay'
import '@/components/components/svg'
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
require("./utils/utils/polyfill"); // 去除touch事件谷歌提示
const app = createApp(App).use(store).use(router)
app.config.globalProperties.$showDialog = showConfirmDialog; //全局挂载
app.component('svg-icon', SvgIcon)
app.component('rowDisplay', rowDisplay)
app.mount('#app');
app.use(vant);
app.directive('draggable', draggable);
// 全局前置守卫
router.beforeEach((to, from, next) => {
    if (!store.state.userInfo.userId) {
        if (localStorage.user) {
            store.state.userInfo = JSON.parse(localStorage.getItem("user"));
        }
    }
    if (to.query.token) {
        localStorage.token = to.query.token
        getUserInfo().then(res => {
            store.commit("updateUserInfo", res.data);
        })
    }
    next()
})
app.config.warnHandler = (msg, vm, trace) => {
    if (msg.includes('[Vue warn]')) {
        return;
    }
};