<template>
  <div
    :class="['app-container',{'isBackground':!state.pageArr.includes($route.path)},{'isBackground2':state.pageArr2.includes($route.path)}]"
    ref="appContainer">
    <transition :name="state.transitionName">
      <headers 
        v-show="state.headersArr.includes($route.path)" 
        :title="$route.query.from"
        :class="[{'headShadow':state.headShadowArr.includes($route.path)}]"
      >
      </headers>
    </transition>
    <router-view
      :class="['router-view-container',{'router-view-container-header': state.headersArr.includes($route.path)}]"
      v-slot="{ Component }">
      <transition :name="state.transitionName" @after-enter="animationFinished">
        <keep-alive :max="10" :exclude="state.keepAliveExclude">
          <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.fullPath" ref="childCom" />
        </keep-alive>
      </transition>
      <transition :name="state.transitionName">
        <component :is="Component" v-if="!$route.meta.keepAlive && !$route.meta.routerView" :key="$route.fullPath" />
      </transition>
      <component :is="Component" v-if="$route.meta.routerView" />
    </router-view>
    <!-- app -->
    <calling-end></calling-end>
    <!-- 支付弹窗 -->
    <pay-box></pay-box>
    <tabbar v-if="$route.path=='/home' || $route.path=='/my' || $route.path=='/home_evimed'"></tabbar>
    <!-- 网络错误 -->
    <reload v-show="$store.state.reloadFlag"></reload>
  </div>
</template>
<script>
  import {
    defineAsyncComponent,
    reactive,
    getCurrentInstance,
    watch,
    onMounted,
    computed,
    setBlockTracking,
    ref,
    nextTick
  } from 'vue'
  import store from '@/store';
  import { markput } from './utils/home';
  export default {
    components: {
      tabbar: defineAsyncComponent(() => import('@/components/components/tabbar')),
      headers: defineAsyncComponent(() => import('@/components/components/headers')),
      payBox: defineAsyncComponent(() => import('@/components/components/paybox.vue')),
      callingEnd: defineAsyncComponent(() => import('@/components/components/callingEnd.vue')),
      reload: defineAsyncComponent(() => import('@/components/components/reload.vue')),
    },
    setup() {
      let {
        appContext: {
          config: {
            globalProperties: router
          }
        }
      } = getCurrentInstance();
      const routeStack = ref([]); // 用于记录路由历史
      const appContainer = ref(null);
      const childCom = ref(null);
      const reloadpage = ref(null);
      const state = reactive({
        pageArr2: ['/my', '/selTest', '/aianalysishome', '/guideHome', '/drugHome', '/drugs-evaluation',
          '/safehome', '/aihistroy', '/tracking', '/tracking-detail', '/error', '/createSubject', '/subject',
          '/task-audit', '/taskInfo-admin', '/diary', '/taskInfo', '/maintenance', '/search-detail',
          '/recharge-activities', '/aianalysisdetails', '/signIn-reward','/clinicalDetail'
        ],
        pageArr: ['/account', '/home', '/home_evimed', '/vertifycode', '/register', '/findpassword', '/repassword',
          '/register_student', '/register_doctor', '/success', '/safehome', '/safehistroy', '/toadd',
          '/search-literature'
        ],
        headersArr: ["/set", '/setInfo', '/uidSet', '/updateTel', '/newNum', '/setEmail', '/updateEmail',
          '/newEmail', '/updatePassword', '/about', '/delUser', '/subset', '/freevip', '/activity', '/bill',
          '/billDetail', '/order', '/orderDetail', '/search-literature', '/selTest', '/subject',
          '/subject-iframe', '/drugHome', '/drugCate', '/guideCate', '/aihistroy', '/createSubject',
          '/task-audit', '/signIn-reward'
        ],
        transitionName: '',
        keepAliveExclude: [],
        headShadowArr: ['/createSubject', '/subject', '/task-audit', '/orderDetail']
      })
      state.keepAliveExclude = computed(() => {
        return store.state.keepAliveExclude
      })
      onMounted(() => {
        setTimeout(() => {
          var hrt = document.body.clientHeight || document.documentElement.clientHeight;
          window.onload = function () {
            document.getElementById('app').style.height = hrt + 'px'
          }
        }, 0)
        let data = localStorage.getItem('selectArr');
        if (data == null) {
          localStorage.setItem('selectArr', store.state.selectArr)
        } else {
          store.state.selectArr = data
        }
      })
      watch(
        () => router.$route,
        (newVal, oldVal) => {
          if (document.getElementsByClassName('van-back-top')[0]) {
            document.getElementsByClassName('van-back-top')[0].remove()
          }
          if (router.$route.query.token) {
            localStorage.setItem('token', router.$route.query.token);
          }
          if (localStorage.app) {
            appContainer.value.style.paddingTop = localStorage.statusHeight + 'px';
          }
          if (router.$route.query.statusHeight) {
            if (router.$route.query.token) {
              localStorage.setItem('app', true);
              localStorage.setItem('statusHeight', router.$route.query.statusHeight);
            }
            if (router.$route.path == '/signIn') {
              nextTick(() => {
                appContainer.value.style.paddingTop = '0px';
                document.getElementsByClassName('signIn_page')[0].style.paddingTop = router.$route.query
                  .statusHeight + 'px';
              })
            } else if (router.$route.path == '/invitation') {
              nextTick(() => {
                appContainer.value.style.paddingTop = '0px';
                document.getElementsByClassName('invitation')[0].style.paddingTop = router.$route.query
                  .statusHeight + 'px';
              })
            } else if (router.$route.path == '/aiOverview') {
              nextTick(() => {
                appContainer.value.style.paddingTop = '0px';
                document.getElementsByClassName('aiOverviewPage')[0].style.paddingTop = router.$route.query
                  .statusHeight + 'px';
              })
            } else {
              let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
              appContainer.value.style.paddingTop = (router.$route.query.statusHeight || localStorage.statusHeight) +
                'px';
              if (isAndroid) {
                store.state.statusHeight = Math.floor(router.$route.query.statusHeight) - 2;
              } else {
                store.state.statusHeight = router.$route.query.statusHeight;
              }
            }
          }
          const currentPath = newVal.fullPath;

          if (routeStack.value.length > 0 && routeStack.value[routeStack.value.length - 1] === currentPath) {
            routeStack.value.pop();
            state.transitionName = 'page-check-right'; // 后退
          } else {
            routeStack.value.push(oldVal.fullPath);
            state.transitionName = 'page-check-left'; // 前进
          }
          channelStatistics(newVal);
        },
      )
      const animationFinished = () => {
        let rescroolPage = ["/aianalysisdetails", "/druganalysis", "/diary", '/subject', '/tracking', '/drugList',
          '/illnessList', '/drugpriceinfo', '/taskInfo', '/referenceDrug'
        ];
        if (rescroolPage.includes(router.$route.path)) {
          childCom.value.recordScrool();
        }
      }
      const channelStatistics=(page)=>{
        if(!page.query.channelCode) return
        store.state.channelCode=page.query.channelCode;
        let localCode=localStorage.channelCode ? JSON.parse(localStorage.channelCode) : null;
        if(localCode && localCode.includes(page.query.channelCode)) return
        let obj={
          channel: page.query.channelCode
        }
        markput(obj).then(res=>{
          if(res.code==200){
            let arr=[];
            if(localCode){
              arr=[...localCode,page.query.channelCode];
            }else{
              arr.push(page.query.channelCode);
            }
            localStorage.setItem('channelCode',JSON.stringify(arr));

          }
        })
      }
      return {
        state,
        appContainer,
        animationFinished,
        childCom
      }
    }
  }
</script>
<style lang="less">
  :root {
    --fontSize0: 24px;
    --fontSize1: 28px;
    --fontSize2: 30px;
    --fontSize3: 32px;
    --Color: #475792;
  }

  div,
  ul,
  li,
  p,
  span {
    margin: 0;
    padding: 0;
    // font-size: var(--fontSize1);
  }

  .app-container {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.isBackground {
      // background: linear-gradient(360deg, #FFFFFF 0%, #CAD0EC 82%, #A2ADE9 100%);
      background: #F5F5F5;
      // background: #FFFFFF;
    }

    &.isBackground2 {
      // background: #F5F5F5;
      background: #FFFFFF;
    }

    .router-view-container {
      box-sizing: border-box;
      // flex: 1;
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      overscroll-behavior: none;
    }

    .headShadow {
      box-shadow: 0px 2px 6px 0px rgba(191, 191, 191, 0.25);
    }
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overscroll-behavior: none;
  }

  #app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    flex: 1;
    font-family: PingFang SC-Medium, PingFang SC, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 24px;
    overflow: hidden;
  }

  @font-face {
    font-family: "iconfont";
    font-weight: 900;
    src: url("//at.alicdn.com/wf/webfont/xpTEUmvJTCi0/mGBcSl0mwiLN.woff2") format("woff2"),
      url("//at.alicdn.com/wf/webfont/xpTEUmvJTCi0/wf8w5fLy4ZZw.woff") format("woff");
    font-display: swap;
  }

  .iconfont {
    font-family: 'iconfont' !important;

  }

  .page-check-left-enter-active,
  .page-check-left-leave-active,
  .page-check-right-enter-active,
  .page-check-right-leave-active {
    position: absolute;
    transition: all 0.15s linear;
    width: 100%;
    /* 确保过渡效果覆盖整个宽度 */
  }

  .page-check-left-enter-from {
    transform: translateX(100%);
    /* 从右进入 */
  }

  .page-check-left-leave-to {
    transform: translateX(-100%);
    /* 向左离开 */
  }

  .page-check-right-enter-from {
    transform: translateX(-100%);
    /* 从左进入 */
  }

  .page-check-right-leave-to {
    transform: translateX(100%);
    /* 向右离开 */
  }

  .router-view-container-header {
    height: calc(100% - 88px);
    bottom: 0;
  }

  .van-back-top {
    --van-back-top-background: transparent;
  }

  .isallloading {
    width: 418px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 2px solid #FFFFFF;

    .van-toast__text {
      color: #333;
    }
  }

  .fsfloat {
    background-color: #fff !important;
    z-index: 3;
    width: 140px !important;
    height: 140px !important;
    opacity: .9;

    .Group {
      width: 140px !important;
      height: 140px !important;
    }
  }
</style>