/*
  draggable  拖动指令
*/
export const draggable = {
    mounted(el) {
      el.style.position = 'fixed';
      el.style.cursor = 'move';
  
      const onMouseDown = (event) => {
        event.preventDefault();
        startDrag(event.clientX, event.clientY);
      };
  
      const onTouchStart = (event) => {
        const touch = event.touches[0];
        startDrag(touch.clientX, touch.clientY);
      };
  
      const startDrag = (startX, startY) => {
        const shiftX = startX - el.getBoundingClientRect().left;
        const shiftY = startY - el.getBoundingClientRect().top;
  
        const moveAt = (pageX, pageY) => {
          el.style.left = pageX - shiftX + 'px';
          el.style.top = pageY - shiftY + 'px';
        };
  
        const onMouseMove = (event) => {
          moveAt(event.pageX, event.pageY);
        };
  
        const onTouchMove = (event) => {
          const touch = event.touches[0];
          moveAt(touch.pageX, touch.pageY);
        };
  
        const stopDrag = () => {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('touchmove', onTouchMove);
          document.removeEventListener('mouseup', stopDrag);
          document.removeEventListener('touchend', stopDrag);
        };
  
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('mouseup', stopDrag);
        document.addEventListener('touchend', stopDrag);
      };
  
      el.addEventListener('mousedown', onMouseDown);
      el.addEventListener('touchstart', onTouchStart);
  
      el.ondragstart = () => {
        return false;
      };
    }
  };